
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import * as util from "@/util";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";

  @Component({
    components: {
      LItem,
      FRadio,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    @Prop() is_spouce: boolean;
    @Prop({ default: util.obj2year() }) year: number;

    created() {
      this._title = "housemate_with_spouce";
      this._need = true;
    }

    get condition() {
      return this.is_spouce && this.is_over_seventy;
    }

    get is_over_seventy() {
      return util.age(this.object.birth_date, `${this.year}-01-01`) >= 69;
    }

    get is_change() {
      return this.object.is_old_spouce_housemate !== this.old_object.is_old_spouce_housemate;
    }
  }
