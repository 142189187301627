
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import IsSpouce from "@/components/FixedTaxReduction/employee/Spouce/IsSpouce.vue";
  import IsSpouceResident from "@/components/FixedTaxReduction/employee/Spouce/Resident.vue";
  import Income from "@/components/FixedTaxReduction/employee/Spouce/Income.vue";
  import EmployeeeachSelfFamilySpouceIsspouceeither from "@/components/employeeeach/self/family/spouce/Isspouceeither.vue";
  import EmployeeeachSelfFamilySpouceName from "@/components/employeeeach/self/family/spouce/Name.vue";
  import EmployeeeachSelfFamilySpouceBirthdate from "@/components/employeeeach/self/family/spouce/Birthdate.vue";
  import EmployeeeachSelfFamilySpouceMynum from "@/components/employeeeach/self/family/spouce/Mynum.vue";
  import EmployeeeachSelfFamilySpouceHousemate from "@/components/employeeeach/self/family/spouce/Housemate.vue";
  import EmployeeeachSelfFamilySpouceAddress from "@/components/employeeeach/self/family/spouce/Address.vue";
  import EmployeeeachSelfFamilySpouceNotresident from "@/components/employeeeach/self/family/spouce/Notresident.vue";
  import EmployeeeachSelfFamilySpouceRemittance from "@/components/employeeeach/self/family/spouce/Remittance.vue";
  import EmployeeeachSelfFamilySpouceIncome from "@/components/employeeeach/self/family/spouce/Income.vue";
  import EmployeeeachSelfFamilySpouceDisability from "@/components/employeeeach/self/family/spouce/Disability.vue";
  import EmployeeeachSelfFamilySpouceDisabilityreason from "@/components/employeeeach/self/family/spouce/Disabilityreason.vue";
  import EmployeeeachSelfFamilySpouceHousematelivelihood from "@/components/employeeeach/self/family/spouce/Housematelivelihood.vue";
  import Earning from "@/entities/Earning";

  @Component({
    components: {
      IsSpouce,
      Income,
      IsSpouceResident,
      EmployeeeachSelfFamilySpouceIsspouceeither,
      EmployeeeachSelfFamilySpouceName,
      EmployeeeachSelfFamilySpouceBirthdate,
      EmployeeeachSelfFamilySpouceMynum,
      EmployeeeachSelfFamilySpouceHousemate,
      EmployeeeachSelfFamilySpouceAddress,
      EmployeeeachSelfFamilySpouceNotresident,
      EmployeeeachSelfFamilySpouceRemittance,
      EmployeeeachSelfFamilySpouceIncome,
      EmployeeeachSelfFamilySpouceDisability,
      EmployeeeachSelfFamilySpouceDisabilityreason,
      EmployeeeachSelfFamilySpouceHousematelivelihood,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop({ default: false }) is_nencho: boolean;
    get tmp_employee() {
      return this.manager.object.employee;
    }
    set tmp_employee(val) {
      this.manager.object.employee = val;
    }
    get raw_object() {
      return this.manager.object.spouce;
    }
    get tmp_object() {
      return this.manager.object.spouce;
    }

    get income_sum() {
      const earning = new Earning();
      return (
        earning.income2earning(Number(this.object.income)) +
        Number(this.object.retire_income) +
        Number(this.object.other_income)
      );
    }
  }
