import { Component, Mixins } from "vue-property-decorator";
import utilMixins from "@/mixins";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";
import fixed_tax_reduction_manager from "@/vuex/fixed_tax_reduction/manager";

import * as util from "@/util";

@Component
export default class mixinFixedTaxReductionEmployee extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.fixed_tax_reduction_new_manager;
  }
  get nm() {
    return fixed_tax_reduction_manager;
  }
  get global() {
    return vuex_data.fixed_tax_reduction_global.get(this);
  }
  get employeely() {
    return vuex_data.fixed_tax_reduction_employeely.get(this);
  }

  get employee_type() {
    return this.employeely.employee.type;
  }

  get fixed_tax_reduction_id() {
    if (this.param_fixed_tax_reduction_id == 0) {
      return this.global.fixed_tax_reduction.id;
    } else {
      return this.param_fixed_tax_reduction_id;
    }
  }
  set fixed_tax_reduction_id(val) {
    this.param_fixed_tax_reduction_id = val;
  }

  get param_fixed_tax_reduction_id() {
    let fixed_tax_reduction_id = this.query.fixed_tax_reduction_id;
    if (fixed_tax_reduction_id === undefined) {
      fixed_tax_reduction_id = 0;
      this.query = { fixed_tax_reduction_id };
    }
    return fixed_tax_reduction_id;
  }
  set param_fixed_tax_reduction_id(val) {
    if (val != this.param_fixed_tax_reduction_id) {
      this.query_push = { fixed_tax_reduction_id: val };
    }
  }

  get employee_id() {
    if (this.is_manager) {
      return this.query.employee_id;
    } else {
      return this.s_employee_id;
    }
  }

  get is_finish_employee_query() {
    return this.query.is_finish_employee;
  }

  get step() {
    return this.m.state.step;
  }
  set step(val) {
    this.m.setState({ name: "step", val });
  }
  get step_hash() {
    const result = {};
    Object.keys(this.steps).forEach((key) => {
      result[this.steps[key].index] = key;
    });
    return result;
  }
  get step_key() {
    return this.step_hash[this.step];
  }
  get steps() {
    const steps = {};
    let index = 1;

    steps["initial"] = {
      index: 0.1,
      name: "",
      is_header: false,
      is_next: true,
      next_index: "base",
      next: util.$t("start"),
    };

    steps["base"] = {
      index: index++,
      name: util.$t("base_info"),
      is_prev: true,
      is_next: true,
      next_index: "address",
      prev_index: "initial",
    };
    steps["address"] = {
      index: index++,
      name: util.$t("address_info"),
      is_prev: true,
      is_next: true,
      next_index: "tax",
      prev_index: "base",
    };
    steps["tax"] = {
      index: index++,
      name: util.$t("self_info"),
      is_prev: true,
      is_next: true,
      next_kou_index: "spouce",
      next_otsu_index: "confirm",
      prev_index: "address",
    };
    steps["spouce"] = {
      index: index++,
      name: util.$t("spouce_info"),
      is_prev: true,
      is_next: true,
      next_index: "dependant",
      prev_index: "tax",
    };
    steps["dependant"] = {
      index: index++,
      name: util.$t("dependant_info"),
      is_prev: true,
      is_next: true,
      next_index: "confirm",
      prev_index: "spouce",
    };
    steps["confirm"] = {
      index: index++,
      name: util.$t("confirm"),
      is_prev: true,
      is_next: true,
      next: util.$t("determine"),
      next_index: "finish",
      prev_kou_index: "dependant",
      prev_otsu_index: "tax",
    };

    steps["finish"] = { index: 10, name: "", is_header: false };

    return steps;
  }
}
