
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import EmployeeeachSelfFamilyDependantName from "@/components/employeeeach/self/family/dependant/Name.vue";
  import EmployeeeachSelfFamilyDependantBirthdate from "@/components/employeeeach/self/family/dependant/Birthdate.vue";
  import EmployeeeachSelfFamilyDependantMynum from "@/components/employeeeach/self/family/dependant/Mynum.vue";
  import EmployeeeachSelfFamilyDependantRelationship from "@/components/employeeeach/self/family/dependant/Relationship.vue";
  import EmployeeeachSelfFamilyDependantHousemate from "@/components/employeeeach/self/family/dependant/Housemate.vue";
  import EmployeeeachSelfFamilyDependantHousematespouce from "@/components/employeeeach/self/family/dependant/Housematespouce.vue";
  import EmployeeeachSelfFamilyDependantAddress from "@/components/employeeeach/self/family/dependant/Address.vue";
  import EmployeeeachSelfFamilyDependantNotresident from "@/components/employeeeach/self/family/dependant/Notresident.vue";
  import EmployeeeachSelfFamilyDependantRemittance from "@/components/employeeeach/self/family/dependant/Remittance.vue";
  import Income from "@/components/FixedTaxReduction/employee/Dependant/Income.vue";
  import EmployeeeachSelfFamilyDependantDisability from "@/components/employeeeach/self/family/dependant/Disability.vue";
  import EmployeeeachSelfFamilyDependantDisabilityreason from "@/components/employeeeach/self/family/dependant/Disabilityreason.vue";
  import EmployeeeachSelfFamilyDependantHousematelivelihood from "@/components/employeeeach/self/family/dependant/Housematelivelihood.vue";
  import EmployeeeachSelfFamilyDependantInternationalstudent from "@/components/employeeeach/self/family/dependant/Internationalstudent.vue";
  import Earning from "@/entities/Earning";
  import IsDependantResident from "@/components/FixedTaxReduction/employee/Dependant/Resident.vue";

  @Component({
    components: {
      EmployeeeachSelfFamilyDependantName,
      EmployeeeachSelfFamilyDependantBirthdate,
      EmployeeeachSelfFamilyDependantMynum,
      EmployeeeachSelfFamilyDependantRelationship,
      EmployeeeachSelfFamilyDependantHousemate,
      EmployeeeachSelfFamilyDependantHousematespouce,
      EmployeeeachSelfFamilyDependantAddress,
      EmployeeeachSelfFamilyDependantNotresident,
      EmployeeeachSelfFamilyDependantRemittance,
      Income,
      EmployeeeachSelfFamilyDependantDisability,
      EmployeeeachSelfFamilyDependantDisabilityreason,
      EmployeeeachSelfFamilyDependantHousematelivelihood,
      EmployeeeachSelfFamilyDependantInternationalstudent,
      IsDependantResident,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() dependant: any;
    @Prop() index: number | string;
    @Prop() is_spouce: boolean;
    @Prop() nencho_year: number;

    get raw_object() {
      return this.dependant;
    }
    get tmp_object() {
      return this.dependant;
    }
    get income_sum() {
      const earning = new Earning();
      return (
        earning.income2earning(Number(this.object.income)) +
        Number(this.object.retire_income) +
        Number(this.object.other_income)
      );
    }
  }
