
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import EmployeeeachSelfTaxTaxtypedetail from "@/components/employeeeach/self/tax/Taxtypedetail.vue";
  import EmployeeeachSelfTaxDependantnum from "@/components/employeeeach/self/tax/Dependantnum.vue";
  import EmployeeeachSelfTaxHouseholdername from "@/components/employeeeach/self/tax/Householdername.vue";
  import EmployeeeachSelfTaxHouseholderrelationship from "@/components/employeeeach/self/tax/Householderrelationship.vue";
  import Resident from "@/components/FixedTaxReduction/employee/Tax/Resident.vue";
  import Income from "@/components/FixedTaxReduction/employee/Tax/Income.vue";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import EmployeeeachSelfTaxDisability from "@/components/employeeeach/self/tax/Disability.vue";
  import EmployeeeachSelfTaxDisabilityreason from "@/components/employeeeach/self/tax/Disabilityreason.vue";
  import EmployeeeachSelfTaxSinglechild from "@/components/employeeeach/self/tax/Singlechild.vue";
  import EmployeeeachSelfTaxWidow from "@/components/employeeeach/self/tax/Widow.vue";
  import EmployeeeachSelfTaxWorkstudent from "@/components/employeeeach/self/tax/Workstudent.vue";
  import EmployeeeachSelfTaxSchool from "@/components/employeeeach/self/tax/School.vue";
  import Earning from "@/entities/Earning";

  @Component({
    components: {
      EmployeeeachSelfTaxTaxtypedetail,
      EmployeeeachSelfTaxDependantnum,
      EmployeeeachSelfTaxHouseholdername,
      EmployeeeachSelfTaxHouseholderrelationship,
      Income,
      LItemlevel,
      EmployeeeachSelfTaxDisability,
      EmployeeeachSelfTaxDisabilityreason,
      EmployeeeachSelfTaxSinglechild,
      EmployeeeachSelfTaxWidow,
      EmployeeeachSelfTaxWorkstudent,
      EmployeeeachSelfTaxSchool,
      Resident,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop({ default: false }) is_nencho: boolean;

    get raw_object() {
      return this.manager.object.employee;
    }
    get tmp_object() {
      return this.manager.object.employee;
    }
  }
