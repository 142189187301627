
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import Earning from "@/entities/Earning";
  import LItem from "@/components/l/Item.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      LItem,
      FText,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    @Prop({ default: true }) show_income_sum: boolean;
    @Prop({ default: false }) is_nencho: boolean;

    created() {
      this._title = "estimated_income";
      this._need = true;
      this._info = "info.earning_fixed_tax_resident";
      this._popup_title = "earning";
      this._popup_content = "popup.earning";
      this.real_other_income = Number(this.object.other_income) + Number(this.object.retire_income);
    }

    detail() {
      window.open("https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1420.htm", "_blank");
    }

    real_other_income = 0;
    inputOtherIncome(val) {
      this.real_other_income = val;
      this.object.other_income = Number(val) - Number(this.object.retire_income);
    }
    inputRetireIncome(val) {
      this.object.other_income = Number(this.real_other_income) - Number(val);
    }

    get show_retire_income() {
      return (
        (this.object.retire_income !== null && this.object.retire_income != 0) ||
        (this.object.other_income !== null && this.object.other_income != 0)
      );
    }

    get income_sum() {
      const earning = new Earning();
      return (
        earning.income2earning(Number(this.object.income)) +
        Number(this.object.retire_income) +
        Number(this.object.other_income)
      );
    }

    get is_change() {
      return (
        this.object.income !== this.old_object.income ||
        this.object.retire_income !== this.old_object.retire_income ||
        this.object.other_income !== this.old_object.other_income
      );
    }
  }
