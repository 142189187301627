
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";

  @Component({
    components: {
      LItem,
      FRadio,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "is_spouce";
      this._need = true;
    }

    get is_change() {
      return this.object.is_spouce !== this.old_object.is_spouce;
    }
  }
