
  import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
  import mixinFixedTaxReductionEmployee from "@/mixins/mixinFixedTaxReductionEmployee";
  import validate from "@/validations/validate";
  import {
    validationEmployeeMail,
    validationEmployeeRoute,
    validationEmployeeStep1ForFixedTaxReduction,
    validationEmployeeStep2,
    validationEmployeeStep4ForFixedTaxReduction,
  } from "@/validations/employee";
  import {
    validationDependantForFixedTaxReduction,
    validationSpouceForFixedTaxReduction,
  } from "@/validations/family";
  import { Provide } from "@/library/provide";
  import LProgress from "@/components/l/Progress.vue";
  import LWizard from "@/components/l/Wizard.vue";
  import LBread from "@/components/l/Bread.vue";
  import Base from "@/components/FixedTaxReduction/employee/Base.vue";
  import Dependants from "@/components/FixedTaxReduction/employee/Dependants.vue";
  import DependantsDisp from "@/components/FixedTaxReduction/employee/Dependantsdisp.vue";
  import Address from "@/components/FixedTaxReduction/employee/Address.vue";
  import Tax from "@/components/FixedTaxReduction/employee/Tax.vue";
  import Spouce from "@/components/FixedTaxReduction/employee/Spouce.vue";
  import LCard from "@/components/l/Card.vue";
  import FButton from "@/components/f/Button.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";
  import Earning from "@/entities/Earning";

  @Component({
    components: {
      LProgress,
      LWizard,
      LBread,
      Base,
      Address,
      Tax,
      Spouce,
      Dependants,
      DependantsDisp,
      LCard,
      FButton,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinFixedTaxReductionEmployee) {
    @Prop() is_finish_employee;

    async created() {
      this.m.initState({ name: "step" });
      this.m.setState({ name: "step", val: 0.1 });
      this.m.initEditManager({ name: "fixed_tax_reduction_employee" });
      await this.fetchedGlobal(this.is_valid);
    }

    get is_valid() {
      return !!this.global && !!this.employeely;
    }

    @Watch("is_valid")
    async fetchedGlobal(is_valid) {
      if (is_valid) {
        if (this.is_finish_employee == 1) {
          const spouce = this.employeely.spouce ?? {
            employee_id: this.employeely.employee.id,
            kana: null,
            name: null,
            birth_date: null,
            mynum: null,
            is_housemate: 1,
            disability_type: 0,
            income: 0,
            retire_income: 0,
            other_income: 0,
            is_not_live: 0,
            address: null,
            not_live_money: null,
            is_special_disability_housemate: 1,
            disability_reason: null,
          };
          spouce.retire_income = spouce.retire_income ?? 0;
          spouce.is_resident = 1;
          const dependants = this.employeely.dependants.map((dependant) => {
            dependant.retire_income = dependant.retire_income ?? 0;
            dependant.is_resident = 1;
            return dependant;
          });
          this.m.edit_manager.fixed_tax_reduction_employee.edit({
            employee: {
              ...this.employeely.employee,
              is_resident: 1,
              is_spouce_deduction: this.employeely.spouce ? 1 : 0,
              carfare_type: this.employeely.employee.carfare_type == 0 ? 0 : 1,
            },
            spouce,
            dependants,
            dependant_num: this.employeely.dependants.length,
          });
          this.m.setState({ name: "step", val: this.steps["confirm"].index });
        } else {
          const spouce = this.employeely.spouce ?? {
            employee_id: this.employeely.employee.id,
            kana: null,
            name: null,
            birth_date: null,
            mynum: null,
            is_housemate: 1,
            disability_type: 0,
            income: 0,
            retire_income: 0,
            other_income: 0,
            is_not_live: 0,
            address: null,
            not_live_money: null,
            is_special_disability_housemate: 1,
            disability_reason: null,
          };
          spouce.retire_income = spouce.retire_income ?? 0;
          spouce.is_resident = 1;
          const dependants = this.employeely.dependants.map((dependant) => {
            dependant.retire_income = dependant.retire_income ?? 0;
            dependant.is_resident = 1;
            return dependant;
          });
          this.m.edit_manager.fixed_tax_reduction_employee.edit({
            employee: {
              ...this.employeely.employee,
              is_spouce_deduction: this.employeely.spouce ? 1 : 0,
              carfare_type: this.employeely.employee.carfare_type == 0 ? 0 : 1,
              is_resident: 1,
            },
            spouce,
            dependants,
            dependant_num: this.employeely.dependants.length,
          });
          this.m.setState({ name: "step", val: 0.1 });
        }
      }
    }

    get employee() {
      return this.m.edit_manager.fixed_tax_reduction_employee.object.employee;
    }
    get dependants() {
      return this.m.edit_manager.fixed_tax_reduction_employee.object.dependants;
    }
    get spouce() {
      return this.m.edit_manager.fixed_tax_reduction_employee.object.spouce;
    }
    get dependant_num() {
      return this.m.edit_manager.fixed_tax_reduction_employee.object.dependant_num;
    }

    async clickNext() {
      if (this.step_key == "initial") {
        this.m.setState({ name: "step", val: this.steps[this.steps["initial"].next_index].index });
      } else if (this.step_key == "base") {
        if (validate(validationEmployeeStep1ForFixedTaxReduction(this.employee))) {
          if (
            validate(
              await validationEmployeeMail(this, {
                employee: this.employee,
                employee_id: this.employee_id,
              }),
            )
          ) {
            this.m.setState({ name: "step", val: this.steps[this.steps["base"].next_index].index });
          }
        }
      } else if (this.step_key == "address") {
        let error = validationEmployeeStep2(this.employee);
        error = error.concat(validationEmployeeRoute(this.employee));
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["address"].next_index].index,
          });
        }
      } else if (this.step_key == "tax") {
        if (validate(validationEmployeeStep4ForFixedTaxReduction(this.employee))) {
          if (this.employee.tax_type == 1 || this.employee.is_resident == 0) {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_otsu_index].index,
            });
          } else {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_kou_index].index,
            });
          }
        }
      } else if (this.step_key == "spouce") {
        if (
          this.employee.is_spouce == 0 ||
          this.employee.is_spouce_deduction == 0 ||
          validate(validationSpouceForFixedTaxReduction(null, { spouce: this.spouce, index: true }))
        ) {
          this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].next_index].index });
        }
      } else if (this.step_key == "dependant") {
        let error = [];
        if (this.dependant_num == 1) {
          error = error.concat(
            validationDependantForFixedTaxReduction(null, {
              dependant: this.dependants[0],
              is_spouce: this.employee.is_spouce == 1,
              index: 0,
            }),
          );
        } else {
          for (let index = 0; index < this.dependant_num; index++) {
            error = error.concat(
              validationDependantForFixedTaxReduction(null, {
                dependant: this.dependants[index],
                is_spouce: this.employee.is_spouce == 1,
                index: index + 1,
              }),
            );
          }
        }
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["dependant"].next_index].index,
          });
        }
      } else if (this.step_key == "confirm") {
        this.nm.updateFixedTaxReductionEmployee({
          fixed_tax_reduction_id: this.fixed_tax_reduction_id,
          employee_id: this.employee_id,
          employee: this.m.edit_manager.fixed_tax_reduction_employee.object.employee,
          spouce: this.m.edit_manager.fixed_tax_reduction_employee.object.spouce,
          dependants: this.m.edit_manager.fixed_tax_reduction_employee.object.dependants,
          query: this.query,
        });
      }
    }
    clickPrev() {
      if (this.step_key == "base") {
        this.m.setState({ name: "step", val: this.steps[this.steps["base"].prev_index].index });
      } else if (this.step_key == "address") {
        this.m.setState({ name: "step", val: this.steps[this.steps["address"].prev_index].index });
      } else if (this.step_key == "tax") {
        this.m.setState({ name: "step", val: this.steps[this.steps["tax"].prev_index].index });
      } else if (this.step_key == "spouce") {
        this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].prev_index].index });
      } else if (this.step_key == "dependant") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["dependant"].prev_index].index,
        });
      } else if (this.step_key == "confirm") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_otsu_index].index,
          });
        }
      }
    }

    get income_sum() {
      const earning = new Earning();
      return (
        earning.income2earning(Number(this.employee.income)) + Number(this.employee.other_income)
      );
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.step < this.steps["confirm"].index;
    }
    @Provide("is_add")
    get is_add() {
      return false;
    }

    @Provide("force_sp")
    get force_sp() {
      return true;
    }
  }
