
  import { Component, Mixins } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";

  @Component({
    components: {
      LItem,
      FRadio,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    created() {
      this._title = "is_resident_fixed_tax_reduction";
      this._need = true;
    }
  }
