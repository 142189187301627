
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import Earning from "@/entities/Earning";
  import { Provide } from "@/library/provide";
  import LItem from "@/components/l/Item.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      LItem,
      FText,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    @Prop() global_config: any;
    @Prop({ default: true }) show_income_sum: boolean;

    created() {
      this._title = "estimated_income";
      this._need = true;
      this._popup_title = "earning";
      this._popup_content = "popup.earning";
    }

    get condition() {
      return this.global_config.showable_company;
    }

    get income_sum() {
      const earning = new Earning();
      return earning.income2earning(Number(this.object.income)) + Number(this.object.other_income);
    }

    @Provide("is_edit")
    get use_is_edit() {
      if (this.s_auth != 1 || this.global_config.editable_company) {
        return this.is_edit;
      } else {
        return false;
      }
    }
    @Provide("is_add")
    get use_is_add() {
      if (this.s_auth != 1 || this.global_config.editable_company) {
        return this.is_add;
      } else {
        return false;
      }
    }

    get is_change() {
      return (
        this.object.income !== this.old_object.income ||
        this.object.other_income !== this.old_object.other_income
      );
    }
  }
